import Vue from 'vue';
import Vuex from 'vuex';

import { alert } from './modules/alert';
import { auth } from './modules/auth';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    auth,
    alert
  }
});