import Vue from 'vue'
import VueRouter from 'vue-router'
import Seller from '../views/Seller.vue'
import VipKey from '../views/VipKey.vue'
import PublicKey from '../views/PublicKey.vue'
import Login from '../views/Login.vue'
import Blacklist from '../views/Blacklist.vue'
import System from '../views/System.vue'
import Shoppy from '../views/Shoppy.vue'
import Transaction from '../views/Transaction.vue'
import Cheat from '../views/Cheat.vue'
import Update from '../views/Update.vue'
import Logs from '../views/NewLogs.vue'
import Apps from '../views/Apps.vue'
import DeletedKey from '../views/DeletedKey'
import Players from '../views/Players'
import DiscountViaCountry from "../views/DiscountViaCountry.vue";
import Online from "../views/Online.vue";
import Firewall from "../views/Firewall.vue";
import Plans from "../views/Plans.vue";
import AuditLogs from "../views/AuditLogs.vue";
import FileManager from '../views/FileServe.vue';
import WebRadar from '../views/Webradar.vue';
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'seller',
    component: Seller
  },
  {
    path: '/players',
    name: 'players',
    component: Players
  },
  {
    path: '/deleted-key',
    name: 'DeletedKey',
    component: DeletedKey
  },
  {
    path: '/vip-key',
    name: 'VipKey',
    component: VipKey
  },
  {
    path: '/public-key',
    name: 'PublicKey',
    component: PublicKey
  },
  {
    path: '/blacklist',
    name: 'Blacklist',
    component: Blacklist
  },
  {
    path: '/system',
    name: 'System',
    component: System
  },
  {
    path: '/shoppy',
    name: 'Shoppy',
    component: Shoppy
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/transaction',
    name: 'transaction',
    component: Transaction
  },
  {
    path: '/cheats',
    name: 'cheats',
    component: Cheat
  },
  {
    path: '/updates',
    name: 'updates',
    component: Update
  },
  {
    path: '/logs',
    name: 'Logs',
    component: Logs
  },
  {
    path: '/logs/:key',
    name: 'LogsWithKey',
    component: Logs
  },
  {
    path: '/apps',
    name: 'Apps',
    component: Apps
  },
  {
    path: '/discount/country',
    name: 'Discount via Country',
    component: DiscountViaCountry
  },
  {
    path: '/online',
    name: 'Online',
    component: Online
  },
  {
    path: '/firewall',
    name: 'Firewall',
    component: Firewall
  },
  {
    path: '/plans',
    name: 'Plans',
    component: Plans
  },
  {
    path: '/audit-logs',
    name: 'Audit Logs',
    component: AuditLogs
  },
  {
    path: '/file-manager',
    name: 'File Manager',
    component: FileManager
  },
  {
    path: '/webradar',
    name: 'Web Radar',
    component: WebRadar
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const LoginPage = publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('Token');

  if (authRequired && !loggedIn) {
    return next('/login');
  }
  if(LoginPage && loggedIn){
    return next('/');
  }
  next();
})
export default router
