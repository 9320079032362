//import { authHeader } from '../helpers';
export const authService = {
    login,
    logout,
};
let apiUrl = "https://backend-owner.egmokka.com";
function login(username, password, googleAuth) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password, googleAuth })
    };              
    return fetch(`${apiUrl}/auth`,requestOptions).then((response)=>{
        return response.json();
    }).then((json)=>{
        if(json.token){
            return Promise.resolve(json.token);
        }
            let error = (json.msg) ? json.msg:"Server Error !!!";
            return Promise.reject(error);
    });
}
function logout() {
    // xoá user từ local storage để log out
    localStorage.removeItem('Token');
}